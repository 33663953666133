<template>
    <div class="nav" id="nav">
      <router-link class="nav-link nav-link-back" v-if="showbackurl" :to="backurl"><img src="@/assets/icons/arrow_left.svg" /></router-link>
      <router-link class="nav-link nav-link-home" to="/uebersicht"><img src="@/assets/icons/home.svg" /></router-link>
    </div>
</template>

<script>
export default {
  name: 'Navigation',

  props: {
    title: String,
    showbackurl: Boolean,
    backurl: String,
  },

  // data() {
  //   return {
  //     duration: null,
  //     status: '',
  //     videourl: '',
  //     backname: 'Home',
  //   };
  // },

  // created() {
  //     this.videourl = this.$route.meta.videourl;
  //     this.backurl = this.$route.meta.backurl;
  //     this.backname = this.$route.meta.backname;
  // },

  // mounted() {
  //   this.$refs.plyr.player.on('play', () => this.onPlay())
  //   this.$refs.plyr.player.on('pause', () => this.onPause())
  //   this.$refs.plyr.player.on('ended', () => this.onEnded())
  // },

  // methods: {
  //   onPlay() {
  //     this.status = 'play'
  //   },
  //   onPause() {
  //     this.status = 'pause'
  //   },
  //   onEnded() {
  //     this.status = 'ended'
  //     this.$router.push({ name: this.backname })
  //   }
  // }
}
</script>